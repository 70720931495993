import React from "react"
import News from "../../../blocks/en/news_page"
import { graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "sushkin_contract" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>On 4 March 2020, Catenaccio Sport Agency obtained an important victory in FIFA Dispute Resolution Chamber on behalf of its client, Mr Vyacheslav Sushkin. The dispute arose from the unilateral termination by the footballer of an employment contract.</p>
          <p>A breach of contract action was brought pursuant to "just cause" principle on behalf of Vyacheslav, whose employment rights for medical treatment and financial payment were neglected by a football club from Uzbekistan. The firm spotted quickly a key flaw in the employment contract and built up an extensive evidential basis arguing that the club was responsible for early termination by the footballer.</p>
          <p>The DRC sided with the firm’s arguments on every issue, rejected all of the club’s claims and, thus confirmed the validity of the early and premature termination. The firm convinced the DRC to award to Vyacheslav significant financial compensation.</p>
        </div>
      </div>
    )
  }
}